@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.container {
  display: flex;
  flex-direction: column;
  padding: $spacing-md 0 $spacing-lg;

  &[data-section-name='ai-search'] {
    padding: 0;

    .carousel {
      display: grid;
      padding: 0 $spacing-md $spacing-md;
      margin-top: $spacing-md;
      grid-auto-flow: column;
      grid-template-columns: repeat(auto-fit, 16.4375rem);

      @include screen-size('medium') {
        margin-top: $spacing-md;
      }
    }
  }

  &[data-section-name='ai-intro'] {
    padding: 1.5rem 0 0;
    gap: 0;

    @include screen-size('small') {
      gap: $spacing-md;
    }

    .carousel {
      padding-bottom: 1.5rem;
      margin-top: 0;
    }
  }

  @include screen-size('medium') {
    padding: 1.5rem 0 3rem;

    &[data-section-name='snaps'] {
      padding: $spacing-sm 0 $spacing-md;
    }

    &[data-section-name='ai-search'] {
      .carousel {
        padding: 0 calc(50% - 320px);
      }

      .top {
        width: calc(100dvw - 10rem);
        max-width: 91.25rem;
        padding: 0;
        margin: 0 auto;
      }
    }
  }

  @include screen-size('content-web') {
    &[data-section-name='brands'],
    &[data-section-name='best-deals'],
    &[data-section-name='collections'],
    &[data-section-name='recommended-deals'],
    &[data-section-name='cheapest-deals'],
    &[data-section-name='recently-viewed'],
    &[data-section-name='no-deposit-deals'] {
      .carousel,
      .top {
        padding: 0 calc(50% - 660px);
      }
    }
  }

  &[data-section-name='search-pills'] {
    position: relative;
    padding: 0;

    .carousel,
    .top {
      padding: 0;
    }

    .button-group {
      width: 100%;
    }

    .controls {
      width: 100%;
      justify-content: space-between;
    }

    .button-left,
    .button-right {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      background: $background-backdrop;
      transition: opacity 0.3s ease;

      @include screen-size('medium') {
        padding: 2.25rem 0;
      }

      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2rem;
        content: '';
      }

      &.disabled {
        opacity: 0;
        visibility: hidden;
      }
    }

    .button-left {
      left: 0;

      &::after {
        left: 2.25rem;
        background: linear-gradient(270deg, transparent 0%, $background-backdrop 100%);
      }
    }

    .button-right {
      right: 0;

      &::after {
        right: 2.25rem;
        background: linear-gradient(90deg, transparent 0%, $background-backdrop 100%);
      }
    }
  }

  &[data-width='content'] {
    &[data-section-name='search-categories'] {
      .carousel,
      .top {
        padding: 0 1rem;

        @include screen-size('medium') {
          padding: 0;
        }

        @include screen-size('large') {
          padding: 0 max(77px, calc(50% - 730px));
        }
      }
    }
  }

  @include screen-size('custom', 1510px) {
    &[data-width='content'] {
      &[data-section-name='affiliates'],
      &[data-section-name='recommended-deals-page'] {
        .carousel,
        .top {
          padding: 0 calc(50% - 730px);
        }
      }

      &[data-section-name='ai-search'] {
        .carousel {
          padding: 0 calc(50% - 320px);
        }
      }
    }

    &[data-width='fixed'] {
      &[data-section-name='affiliates'],
      &[data-section-name='recommended-deals-page'] {
        .carousel {
          padding: 0 calc(50% - 660px);
        }
      }
    }
  }
}

.top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 $spacing-md;

  &.no-header {
    flex-direction: row-reverse;
  }

  [data-section-name='snaps'] & {
    width: 100%;
    max-width: $wrapper-width;
    margin-right: auto;
    margin-left: auto;

    .button-group {
      display: none;

      @include screen-size('medium') {
        display: flex;
        margin-right: 1rem;
      }

      @include screen-size('large') {
        margin-right: 0;
      }
    }
  }

  h2 {
    @include display-xs;
    @include text-bold;

    margin-bottom: 0;
    color: $label-text;
  }

  p {
    @include text-normal;

    color: $label-text;
    font-size: 0.75rem;
    line-height: 1.4;
  }

  .controls {
    display: none;
    align-items: flex-end;
    gap: $spacing-xs;

    @include screen-size('small') {
      display: flex;
    }
  }

  .button-group {
    display: flex;
    align-items: flex-end;
    gap: $spacing-xs;
  }
}

.carousel {
  display: flex;
  max-width: 100%;
  flex-flow: row nowrap;
  padding: 0 $spacing-md;
  margin-top: 1rem;
  gap: $spacing-xs;
  overflow-x: scroll;
  scrollbar-width: none;

  [data-section-name='ai-search'] & {
    padding: 0 $spacing-sm;
  }

  [data-section-name='affiliates'] & {
    padding: 0 1rem;

    > div {
      display: flex;
      gap: $spacing-xs;
    }
  }

  [data-section-name='snaps'] & {
    padding: 0 $spacing-sm;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
