@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.container {
  container: vehicle / inline-size;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &[data-type='grid'] {
    min-width: 18rem;
  }

  &[data-type='carousel'] {
    width: 20rem;
    max-height: 30rem;
    flex-shrink: 0;
  }

  &[data-type='carousel-ai'] {
    width: 16.4375rem;
    min-height: 26.3125rem;
    max-height: 30rem;
    flex-shrink: 0;
  }
}

.main {
  display: grid;
  align-content: flex-start;
  padding: 0;
  border-radius: $spacing-sm;
  color: $label-text;
  gap: 0.75rem;
  text-decoration: none;

  @include screen-size('medium') {
    padding: 0 0 $spacing-sm;
  }

  *[data-is-desktop-only] {
    display: none;
  }

  *[data-is-mobile-only] {
    display: flex;
  }
}

.image-container {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: $spacing-xs;
  aspect-ratio: 1/1;
  background-color: $fill-inactive-light;

  .score {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }
}

.detail-container {
  display: grid;
  color: $label-text;
}

.make-model {
  @include text-md;
  @include text-bold;

  margin: 0;
}

.lease-profile {
  @include text-sm;
  @include text-normal;

  color: $label-supporting-light;
}

.body-type {
  @include text-sm;
  @include text-normal;

  color: $label-supporting-light;

  span {
    @include text-bold;
  }

  @include screen-size('medium') {
    @include text-xs;
  }
}

.monthly-price {
  @include text-md;
  @include text-normal;

  margin-top: $spacing-xs;
  gap: $spacing-xxs;

  span {
    @include text-bold;

    color: $label-text;
  }
}

.pcp-saving {
  @include text-md-link;

  color: rgb(52 199 89 / 100%);
}

.summary-container {
  display: none;
  padding: $spacing-sm;
  border: 2px solid $fill-inactive-light;
  border-bottom-right-radius: $spacing-xs;
  border-top-right-radius: $spacing-xs;
  place-content: space-between stretch;

  .score[data-is-inline='true'] {
    padding: $spacing-xxs;

    > span {
      margin-right: 0.75rem;
      transform: scale(1.414);
      transform-origin: center center;
    }
  }

  .action {
    display: grid;
  }

  .pcp-saving {
    @include text-sm;
    @include text-bold;

    text-decoration: none;
  }

  .monthly-price {
    @include text-md;

    span.small-text {
      @include text-xs;
      @include text-normal;
    }

    span {
      @include text-xl;
      @include text-bold;
    }
  }
}

.badges {
  display: flex;
  align-items: flex-start;
  padding-bottom: $spacing-xs;
  gap: $spacing-xxs;
}

.attributes {
  display: flex;
  flex-wrap: wrap;
  padding: $spacing-xs 0 0 0;
  margin: 0;
  gap: $spacing-xs $spacing-sm;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    gap: $spacing-xs;
  }
}

.derivative {
  @include text-sm;
  @include text-normal;
}

.configure {
  padding: 0.625rem $spacing-sm;
  border-radius: 10rem;
  margin-top: $spacing-sm;
  background-color: $brand;
  color: $label-primary-dark;
  text-align: center;

  &:hover {
    background-color: $brand;
  }
}

@container vehicle (width > 756px) {
  .main {
    min-height: 256px;
    align-content: unset;
    padding-bottom: 0;
    background-color: $white;
    gap: 0;
    grid-template-columns: auto 1fr 15rem;

    *[data-is-desktop-only] {
      display: flex;
    }

    *[data-is-mobile-only] {
      display: none;
    }
  }

  .detail-container {
    align-content: space-between;
    padding: $spacing-sm;
    border: 2px solid $fill-inactive-light;
    border-right: 0;
    border-left: 0 none;
  }

  .image-container {
    max-width: 290px;
    border-radius: 1rem 0 0 1rem;
    aspect-ratio: unset;

    .score {
      opacity: 0;
      visibility: hidden;
    }
  }

  .summary-container {
    display: grid;
    border-radius: 0 1rem 1rem 0;
  }

  .make-model,
  .badges {
    align-self: flex-start;
  }

  .make-model {
    @include text-xl;
  }

  .body-type {
    display: inline-grid;
    padding-bottom: $spacing-sm;
    color: $label-text;
  }

  .lease-profile {
    @include text-bold;

    .hidden-extensions {
      display: inline-block;
      transition: width 0.1s ease-in-out;

      @include screen-size('custom', 1200px) {
        width: 0;
        appearance: none;
        opacity: 0;
      }

      @include screen-size('custom', 1355px) {
        width: auto;
        appearance: auto;
        opacity: 1;
      }
    }

    align-self: flex-end;
    order: 10;
    padding-top: $spacing-sm;
    border-top: 1px solid $fill-inactive-light;
    color: $label-text;
  }
}

@container vehicle (width < 950px) {
  @include screen-size('medium') {
    .body-type {
      display: none;
    }
  }
}

@container search-results (width < 821px) {
  .body-type {
    display: block;
  }
}
