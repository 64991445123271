@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.container {
  position: relative;
  z-index: 5;
  display: flex;
  overflow: visible;
  width: 100%;
  height: 24.25rem;
  padding: 0 0 $spacing-lg;
  border-radius: 0 0 2.5rem 2.5rem;
  background-color: transparent;
  text-align: left;

  &.variant-1,
  &.variant-2 {
    height: 30.365rem;
  }

  &.variant-2 {
    padding: 0 0 $spacing-md;
  }

  &.variant-1 {
    padding: 0 $spacing-md $spacing-md;
  }

  @include screen-size('small') {
    height: 30.625rem;
    padding: 3.125rem 0;

    &.variant-1,
    &.variant-2 {
      padding: 0 $spacing-md $spacing-md;
    }
  }

  @include screen-size('medium') {
    align-items: center;
    border-radius: 0;

    &.variant-1 {
      padding: 0 $spacing-md;
    }
  }

  @include screen-size('custom', 1352px) {
    max-width: 1320px;
    padding-right: 0;
    padding-left: 0;
    margin: 0 auto;

    &.variant-1 {
      padding: 0;
    }
  }
}

.rear-mirror {
  position: absolute;
  z-index: 1;
  top: 0;
  right: calc(50% - 73px);
  display: block;
  width: 152px;
  height: 64px;

  .variant-1 &,
  .variant-2 & {
    display: none;
  }

  @include screen-size('custom', 380px) {
    top: -0.5rem;
  }

  @include screen-size('custom', 430px) {
    top: 0;
  }

  @include screen-size('custom', 630px) {
    top: 0.5rem;
  }

  @include screen-size('small') {
    top: 0;
  }

  @include screen-size('custom', 820px) {
    top: 0.5rem;
  }

  @include screen-size('iphone-14') {
    top: -0.25rem;
  }

  @include screen-size('iphone-14-pro') {
    top: -0.25rem;
  }

  @include screen-size('medium') {
    top: -1.5rem;
    right: 1rem;
    width: auto;
    height: auto;
    flex-shrink: 0;

    .variant-1 & {
      display: block;
    }
  }

  @include screen-size('extra-large') {
    right: 10rem;
  }

  @include screen-size('custom', 1260px) {
    top: 0;
  }
}

.content {
  z-index: 25;
  display: grid;
  width: 100%;
  padding: 0 1rem;
  padding-bottom: 0;
  gap: $spacing-sm;
  grid-template-columns: minmax(18rem, 31.75rem);
  place-content: flex-end center;

  .variant-1 &,
  .variant-2 & {
    padding: 0;
    text-align: center;

    div {
      justify-content: center;
    }
  }

  a {
    width: 100%;
  }

  @include screen-size('medium') {
    gap: $spacing-lg;
    grid-template-columns: 1fr;
    text-align: left;

    .variant-1 &,
    .variant-2 & {
      gap: $spacing-md;
      text-align: left;

      div {
        justify-content: flex-start;
      }
    }

    a {
      width: auto;
    }
  }

  @include screen-size('content-web') {
    padding: 0;
  }
}

.animation {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: none;
  }

  position: absolute;
  z-index: 0;
  top: 52px;
  right: 0;
  left: 0;
  display: block;
  overflow: visible;
  width: 275px;
  height: auto;
  flex-grow: 1;
  margin: 0 auto;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  perspective: 1000;
  pointer-events: none;
  transform: translate3d(0, 0, 0);

  .variant-1 & {
    top: 0;
  }

  //margin: 0 auto; // black friday only
  //top: 0; // black friday only

  @include screen-size('small') {
    top: 63px;
    width: 500px;
    margin: 0 auto;

    //top: 3rem; // black friday only
  }

  @include screen-size('iphone') {
    top: 56px;

    //top: 1rem; // black friday only
  }

  @include screen-size('iphone-14') {
    top: 50px;

    //top: 1rem; // black friday only
  }

  @include screen-size('iphone-14-pro') {
    top: 50px;

    //top: 1rem; // black friday only
  }

  @include screen-size('custom', 630px) {
    top: 60px;
  }

  @include screen-size('small') {
    width: 600px;

    //width: 217px; // black friday only
  }

  @include screen-size('medium') {
    top: 6.5rem;
    right: -12rem;
    left: auto;
    width: 750px;
    margin: 0;

    .variant-1 & {
      top: 6.5rem;
    }

    //right: 1rem; // black friday only
    //top: 1rem; // black friday only
    //width: 217px; // black friday only
  }

  @include screen-size('extra-large') {
    right: -3rem;
    width: 750px;

    //right: 3rem; // black friday only
    //width: 217px; // black friday only

    .variant-2 & {
      width: 500px;
    }
  }

  @include screen-size('custom', 1260px) {
    top: 7.5rem;

    .variant-1 & {
      top: 7.5rem;
    }

    //top: 1rem; // black friday only

    .variant-2 & {
      width: 500px;
    }
  }

  .variant-2 & {
    top: 0;
  }
}

.extras {
  position: absolute;
  z-index: 5;
  bottom: 2rem;
  left: 1rem;
  display: flex;
  width: calc(100% - 2rem);
  align-items: center;
  justify-content: space-between;

  @include screen-size('small') {
    bottom: 3rem;
  }

  @include screen-size('medium') {
    bottom: 3rem;
    left: auto;
  }
}

.widget {
  display: none;

  @include screen-size('medium') {
    display: block;
  }
}

.bottom {
  position: absolute;
  z-index: 0;
  bottom: -11rem;
  left: 0;
  display: block;
  width: 200%;
  height: 100%;

  @include screen-size('iphone-14') {
    bottom: -11rem;
  }

  @include screen-size('extra-small') {
    bottom: -11rem;
  }

  @include screen-size('small') {
    bottom: -17rem;
  }

  @include screen-size('medium') {
    display: none;
  }
}

.title {
  z-index: 5;
  display: grid;
  width: 100%;
  max-width: 34rem;
  margin: 0 auto;
  text-align: center;

  .variant-1 &,
  .variant-2 & {
    gap: 0.75rem;
  }

  @include screen-size('small') {
    text-align: left;

    .variant-1 &,
    .variant-2 & {
      text-align: center;
    }
  }

  @include screen-size('medium') {
    margin: 0;
    text-align: left;

    .variant-1 &,
    .variant-2 & {
      gap: $spacing-xs;
      text-align: left;
    }
  }

  @include screen-size('extra-large') {
    max-width: 42rem;
  }

  h1,
  h2 {
    margin-bottom: 0;
    color: $label-primary-dark;
  }

  h1 {
    @include display-sm;
    @include text-bold;

    .variant-1 &,
    .variant-2 & {
      @include display-lg;
    }

    @include screen-size('medium') {
      @include display-2xl;

      .variant-1 &,
      .variant-2 & {
        @include display-2xl;
      }
    }
  }

  h2 {
    @include text-sm;
    @include text-bold;

    .variant-1 &,
    .variant-2 & {
      @include body-md;
      @include text-normal;
    }

    @include screen-size('medium') {
      @include text-xl;

      .variant-1 &,
      .variant-2 & {
        @include section-header;
      }
    }
  }
}

.pills {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: $spacing-sm;
  gap: $spacing-xs;

  @include screen-size('small') {
    justify-content: flex-start;
  }

  &__title {
    @include text-sm;
    @include text-normal;

    color: $label-primary-dark;

    @include screen-size('medium') {
      @include text-md;
    }
  }

  &__container {
    @include text-sm-link;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: 0;
    color: $label-primary-dark;
    gap: 0.5rem;
    list-style: none;
  }
}
